import React from "react";
import { CardBoard } from "components/CardBoard";
import { Card } from "components/Card";
import { Card as PlayingCard } from "models/Card";
import { Hand } from "models/Hand";

export type BoardCard = PlayingCard | null;
export type BoardHand = Hand | null;

type DeckData = {
  deck_id: string;
  errors: string[];
};

type CardData = {
  card: PlayingCard | null;
  errors: string[];
};

const initBoardCards = () => {
  return [...Array(5)].map((row) => Array(5).fill(null));
};

const initHands = () => {
  return Array(5).fill(null);
};

const drawCard = async (deckId: string): Promise<CardData> => {
  const url = `https://deckofcardsapi.com/api/deck/${deckId}/draw`;

  const response = await window.fetch(url);

  if (!response.ok) {
    return { card: null, errors: ["there was an error drawing a card"] };
  }

  const { cards } = await response.json();

  return { card: cards[0], errors: [] };
};

const fetchDeck = async (): Promise<DeckData> => {
  const url = "https://deckofcardsapi.com/api/deck/new/shuffle";

  const response = await window.fetch(url);

  if (!response.ok) {
    return { deck_id: "", errors: ["there was an error"] };
  }

  const { deck_id } = await response.json();

  return { deck_id, errors: [] };
};

export const PokerPatience = (): JSX.Element => {
  const [boardCards, setBoardCards] =
    React.useState<BoardCard[][]>(initBoardCards);
  const [deckId, setDeckId] = React.useState<string>("");
  const [deckErrors, setDeckErrors] = React.useState<string[]>([]);
  const [nextCard, setNextCard] = React.useState<PlayingCard | null>(null);
  const [score, setScore] = React.useState<number>(0);
  const [colHands, setColHands] = React.useState<BoardHand[]>(initHands);
  const [rowHands, setRowHands] = React.useState<BoardHand[]>(initHands);
  const [handsCompleted, setHandsCompleted] = React.useState<number>(0);

  const cardClick = (row: number, col: number): void => {
    const boardCard = boardCards[row][col];
    if (!boardCard) {
      if (nextCard) {
        setNextCard(null);
        const newBoardCards = [...boardCards];
        newBoardCards[row][col] = nextCard;
        setBoardCards(newBoardCards);
        updateHands(row, col);
        if (!gameOver()) {
          drawNextCard(deckId);
        }
      }
    }
  };

  const drawNextCard = React.useCallback(async (deck_id: string) => {
    const { card } = await drawCard(deck_id);

    if (card) {
      const { code, suit, value, image } = card;
      const newCard = new PlayingCard(image, value, suit, code);
      setNextCard(newCard);
    }
  }, []);

  const gameOver = (): boolean => {
    return handsCompleted === 10;
  };

  const newGame = (): void => {
    console.log("click");
    setDeckId("");
    setBoardCards(initBoardCards);
    setNextCard(null);
    setScore(0);
    setColHands(initHands);
    setRowHands(initHands);
    setHandsCompleted(0);
  };

  const updateHands = (row: number, col: number): void => {
    if (!rowHands[row]) {
      //should this ever happen?
      const rowCards = boardCards[row].filter((c) => c);
      if (rowCards.length === 5) {
        const hand = new Hand(rowCards as PlayingCard[]);
        const newRowHands = [...rowHands];
        newRowHands[row] = hand;
        setRowHands(newRowHands);
        setHandsCompleted((hc) => hc + 1);
        setScore((s) => s + hand.value());
      }
    }
    if (!colHands[col]) {
      //should this ever happen?
      const potentialColCards = [];
      for (var i = 0; i < 5; i++) {
        potentialColCards.push(boardCards[i][col]);
      }
      const colCards = potentialColCards.filter((c) => c);
      if (colCards.length === 5) {
        const hand = new Hand(colCards as PlayingCard[]);
        const newColHands = [...colHands];
        newColHands[col] = hand;
        setColHands(newColHands);
        setHandsCompleted((hc) => hc + 1);
        setScore((score) => score + hand.value());
      }
    }
  };

  React.useEffect(() => {
    const fetchTheDeck = async () => {
      const { deck_id, errors } = await fetchDeck();
      if (errors.length > 0) {
        setDeckId("");
        setDeckErrors(errors);
      } else {
        setDeckId(deck_id);
        setDeckErrors([]);
        drawNextCard(deck_id);
      }
    };

    if (!deckId && deckErrors.length === 0) {
      fetchTheDeck();
    }
  }, [deckId, deckErrors, drawNextCard]);

  const renderNextCard = (): JSX.Element => {
    if (gameOver()) {
      return (
        <div>
          <div>
            <h4>Final Score: {score}</h4>
          </div>
          <div>
            <button onClick={newGame}>New Game</button>
          </div>
        </div>
      );
    }
    return (
      <>{nextCard ? <Card card={nextCard} /> : <div>...Dealing...</div>}</>
    );
  };
  return (
    <div className="container">
      <div className="next-card">
        <a
          className="game-rules"
          target="blank"
          href="https://en.wikipedia.org/wiki/Poker_squares"
        >
          Game Rules and Scoring
        </a>
        <div>{renderNextCard()}</div>
      </div>
      <div className="card-board">
        <h2 className="card-board-header">Poker Solitaire</h2>
        <CardBoard
          cards={boardCards}
          cardClick={cardClick}
          rowHands={rowHands}
          colHands={colHands}
        />
        <div className="board-summary">
          <div>
            <span className="board-summary-label">Current score:</span>
            {score}
          </div>
          <div>
            <span className="board-summary-label">Hands completed:</span>
            {handsCompleted}
          </div>
        </div>
      </div>
    </div>
  );
};
