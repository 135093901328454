export interface iCard {
  image: string;
  value: string;
  suit: string;
  code: string;
  rank: () => number;
}

export class Card implements iCard {
  image: string;
  value: string;
  suit: string;
  code: string;

  constructor(image: string, value: string, suit: string, code: string) {
    this.image = image;
    this.value = value;
    this.suit = suit;
    this.code = code;
  }

  description(): string {
    return `${this.value} of ${this.suit}`;
  }

  rank(): number {
    switch (this.value) {
      case "KING":
        return 13;
      case "QUEEN":
        return 12;
      case "JACK":
        return 11;
      case "0":
        return 10;
      case "ACE":
        return 1;
      default:
        return parseInt(this.value);
    }
  }
}
