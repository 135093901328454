import { BoardHand } from "components/PokerPatience";
import { BoardCard } from "components/PokerPatience";
import { Card } from "components/Card";

interface CardBoardProps {
  cards: BoardCard[][];
  rowHands: BoardHand[];
  colHands: BoardHand[];
  cardClick: (row: number, col: number) => void;
}
export const CardBoard = ({
  cards,
  rowHands,
  colHands,
  cardClick,
}: CardBoardProps): JSX.Element => {
  const tdClick = (e: React.MouseEvent<HTMLElement>) => {
    const row = parseInt(e.currentTarget.getAttribute("data-row") || "0");
    const col = parseInt(e.currentTarget.getAttribute("data-col") || "0");

    cardClick(row, col);
  };

  const generateCardRows = (): JSX.Element[] => {
    const rows = cards.map((cardRow, row) => {
      const cells: JSX.Element[] = [];
      cells.push(
        <td key={"rowlabel" + row}>
          <div className="row-hand-description">
            {rowHands[row] ? rowHands[row]?.description() : ""}
          </div>
        </td>
      );
      cardRow.map((card, col) =>
        cells.push(
          <td
            onClick={tdClick}
            data-row={row}
            data-col={col}
            key={`row${row}col${col}`}
          >
            <Card card={card} />
          </td>
        )
      );
      return <tr key={`row${row}`}>{cells}</tr>;
    });
    const bottomCells = [<td key={"empty_bottom"}></td>];
    colHands.forEach((hand, i) =>
      bottomCells.push(
        <td key={`col${i}desc`} className="col-hand-description">
          {hand ? hand.description() : ""}
        </td>
      )
    );
    rows.push(<tr key={"bottom"}>{bottomCells}</tr>);

    return rows;
  };

  return (
    <table>
      <tbody>{generateCardRows()}</tbody>
    </table>
  );
};
