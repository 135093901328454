import { Card as PlayingCard } from "models/Card";

interface CardProps {
  card: PlayingCard | null;
}

export const Card = ({ card }: CardProps): JSX.Element => {
  const occupied = card ? "card-occupied" : "";
  return (
    <div
      className={`card ${occupied}`}
      title={card ? card.description() : "place card here"}
    >
      {card ? (
        <img className="card-image" src={card.image} alt={card.description()} />
      ) : null}
    </div>
  );
};
