import { iCard } from "models/Card";

export enum HandDescription {
  ROYAL_FLUSH = "Royal Flush",
  STRAIGHT_FLUSH = "Straight Flush",
  FOUR_OF_KIND = "Four of a Kind",
  FULL_HOUSE = "Full House",
  FLUSH = "Flush",
  STRAIGHT = "Straight",
  THREE_OF_KIND = "Three of a Kind",
  TWO_PAIRS = "Two Pairs",
  ONE_PAIR = "One Pair",
  NOTHING = "Nothing",
}

enum HandValue {
  "Royal Flush" = 100,
  "Straight Flush" = 75,
  "Four of a Kind" = 50,
  "Full House" = 25,
  "Flush" = 20,
  "Straight" = 15,
  "Three of a Kind" = 10,
  "Two Pairs" = 5,
  "One Pair" = 2,
  "Nothing" = 0,
}

export class Hand {
  cards: iCard[];

  constructor(cards: iCard[]) {
    this.cards = cards;
  }

  description(): HandDescription {
    const straight = this.isStraight();
    const flush = this.isFlush();

    if (straight && flush) {
      return this.isAceHighStraight()
        ? HandDescription.ROYAL_FLUSH
        : HandDescription.STRAIGHT_FLUSH;
    }
    if (straight) {
      return HandDescription.STRAIGHT;
    }
    if (flush) {
      return HandDescription.FLUSH;
    }
    if (this.isFourOfKind()) {
      return HandDescription.FOUR_OF_KIND;
    }
    if (this.isFullHouse()) {
      return HandDescription.FULL_HOUSE;
    }

    if (this.isThreeOfKind()) {
      return HandDescription.THREE_OF_KIND;
    }

    if (this.isTwoPairs()) {
      return HandDescription.TWO_PAIRS;
    }

    if (this.isOnePair()) {
      return HandDescription.ONE_PAIR;
    }

    return HandDescription.NOTHING;
  }

  value(): number {
    const d = this.description();
    return HandValue[d as keyof typeof HandValue];
  }

  private isFlush(): boolean {
    return this.cards.every((card) => card.suit === this.cards[0].suit);
  }

  private isFourOfKind(): boolean {
    const ranksSet = this.ranksSet();

    if (ranksSet.size !== 2) {
      return false;
    }

    const uniqueRanks = Array.from(ranksSet);
    return uniqueRanks.some((r) => this.rankCount(r) === 4);
  }

  private isFullHouse(): boolean {
    const ranksSet = this.ranksSet();

    if (ranksSet.size !== 2) {
      return false;
    }

    const uniqueRanks = Array.from(ranksSet);
    return (
      uniqueRanks.some((r) => this.rankCount(r) === 3) &&
      uniqueRanks.some((r) => this.rankCount(r) === 2)
    );
  }

  private isOnePair(): boolean {
    return this.ranksSet().size === 4;
  }

  private isThreeOfKind(): boolean {
    const ranksSet = this.ranksSet();

    if (ranksSet.size !== 3) {
      return false;
    }

    const uniqueRanks = Array.from(ranksSet);
    return uniqueRanks.some((r) => this.rankCount(r) === 3);
  }

  private isStraight(): boolean {
    const ranks = this.ranks();
    const myRanksSet = new Set(ranks);

    if (myRanksSet.size !== 5) {
      return false;
    }

    if (ranks[4] - ranks[0] === 4) {
      return true;
    }

    //special case ace high straight
    if (ranks[0] === 1) {
      return ranks[4] === 13 && ranks[1] === 10;
    }

    return false;
  }

  private isTwoPairs(): boolean {
    const ranks = this.ranks();
    const ranksSet = new Set(ranks);

    if (ranksSet.size !== 3) {
      return false;
    }

    const uniqueRanks = Array.from(ranksSet);
    const uniqueRanksWithTwo = uniqueRanks.filter(
      (r) => this.rankCount(r) === 2
    );
    return uniqueRanksWithTwo.length === 2;
  }

  //optimized for already know it is a str8
  private isAceHighStraight(): boolean {
    const myRanks = this.ranks();

    return myRanks[0] === 1 && myRanks[4] === 13;
  }

  private ranks(): number[] {
    return this.cards.map((card) => card.rank()).sort((c1, c2) => c1 - c2);
  }

  private ranksSet(): Set<number> {
    return new Set(this.ranks());
  }

  private rankCount(rank: number): number {
    const myRanks = this.ranks();
    return myRanks.reduce((c, v) => (v === rank ? c + 1 : c), 0);
  }
}
